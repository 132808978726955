
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';

@Component({})
export default class WhatsappDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  
  messageInput: string = '';
  userMessage: string = '';
  currentTime: string = '';
  isTyping: boolean = false;
  responseMessages: Array<{text: string, time: string}> = [];
  
  autoResponses: string[] = [
    "Teşekkürler, mesajınızı aldık. Size en kısa sürede dönüş yapacağız.",
    "Sorunuzu ilgili ekibimize ilettik. Birazdan size WhatsApp üzerinden dönüş yapılacaktır.",
    "Talebiniz alınmıştır. Detaylı bilgi için WhatsApp üzerinden devam edebilirsiniz.",
    "Teşekkürler! WhatsApp üzerinden görüşmeye devam edebiliriz.",
    "Mesajınız için teşekkürler. Uzman ekibimiz sizinle WhatsApp üzerinden iletişime geçecektir."
  ];

  get dialog(): boolean {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit('input', value);
  }
  
  mounted() {
    this.currentTime = this.getCurrentTime();
    
    // Scroll to bottom when messages are added
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  }
  
  getCurrentTime() {
    return new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }
  
  scrollToBottom() {
    const container = this.$refs.chatContainer as HTMLElement;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }
  
  formatMessage(text: string): string {
    // URL'leri bağlantılara dönüştür
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, '<a href="$1" target="_blank" class="message-link">$1</a>');
  }
  
  sendMessage() {
    if (this.messageInput.trim()) {
      this.userMessage = this.messageInput;
      this.messageInput = '';
      this.currentTime = this.getCurrentTime();
      
      // Scroll to bottom after sending message
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      
      // Yazıyor efekti
      this.isTyping = true;
      
      // Otomatik cevap
      setTimeout(() => {
        this.isTyping = false;
        const randomResponse = this.autoResponses[Math.floor(Math.random() * this.autoResponses.length)];
        this.responseMessages.push({
          text: randomResponse,
          time: this.getCurrentTime()
        });
        
        // Scroll to bottom after response
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        
        // Otomatik olarak WhatsApp'a yönlendirme
        setTimeout(() => {
          this.openWhatsApp();
        }, 2000);
      }, 1500);
    }
  }
  
  openWhatsApp() {
    const phoneNumber = "905309749954";
    let message = "Merhabalar 👋 ";
    
    if (this.userMessage) {
      message += this.userMessage;
    } else {
      message += "Size nasıl yardımcı olabiliriz?";
    }
    
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    this.dialog = false;
  }

  @Watch('dialog')
  onDialogChange(newVal: boolean) {
    if (newVal) {
      // Dialog açıldığında mesajları sıfırla
      this.userMessage = '';
      this.messageInput = '';
      this.responseMessages = [];
      this.isTyping = false;
      
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  }
}
