import app from "@/main";
import {ITask} from "../store/modules/GorevYoneticisiTypes";
import TakipDosya from "./classes/TakipDosya";
import AvukatIcraDosyalari, {AvukatIcraDosyalariTalep, DosyaDurum} from "./AvukatIcraDosyalari";
import {IAvukatDosya} from "./CommonInterfaces";

export class DosyaBul {
    err: any = {};

    constructor() {
        this.err = {message: "Dosya Bulunamadı !"};
    }

    async getMockResponse(): Promise<TakipDosya> {
        console.log("get MockRespinse");
        let adsg = new AvukatIcraDosyalari();
        let data = await adsg.parse(adsg.getMockResponse());
        return TakipDosya.fromJson(data.items[0]);
    }

    run(dosya: ITask, recursiveQuery = false): Promise<TakipDosya> {
        return new Promise(async (resolve, reject) => {
            try {
                if(app.$store.getters.isEicraproDevMode){
                    return resolve(await this.getMockResponse());
                }
                console.log("DosyaBul dosya", dosya);
                let dosyaEsasNo = dosya.dosya_esas_no;
                let icraDairesi = dosya.birim_adi;
                let splited = dosyaEsasNo.split('/');
                let dosyaYil = splited[0].trim();
                if (splited[1].indexOf("-") >= 0) {
                    splited[1] = splited[1].split('-')[0].trim(); //2016/7232 - ( 2015/7070 ) bunu cevirir normale
                }
                let dosyaSira = splited[1].trim();
                let requestData = new AvukatIcraDosyalariTalep();
                requestData.dosyaYil = parseInt(dosyaYil);
                requestData.dosyaSira = parseInt(dosyaSira);
                let acikDosya = await app.$uyap.AvukatIcraDosyalari().run(requestData);
                if (acikDosya.items.length > 0) {
                    return resolve(
                        await this.sorgulananDosyaEslestir(acikDosya.items, icraDairesi, dosyaEsasNo)
                    );
                } else {
                    requestData.dosyaDurumKod = DosyaDurum.KAPALI;
                    let kapaliDosya = await app.$uyap.AvukatIcraDosyalari().run(requestData);
                    if (kapaliDosya.items.length > 0) {
                        return resolve(
                            await this.sorgulananDosyaEslestir(kapaliDosya.items, icraDairesi, dosyaEsasNo)
                        );
                    } else {
                        return reject(this.err);
                    }
                }
            } catch (e) {
                console.log("eerror dosyabul",e);
                return reject(e);
            }
        })
    }

    sorgulananDosyaEslestir(data: Array<IAvukatDosya>, icraDairesi: string, dosyaEsasNo: string): Promise<TakipDosya> {
        return new Promise((resolve, reject) => {
            if (data.length > 0) {
                let takipDosyalar = data.map((x: any)=> TakipDosya.fromJson(x));
                if(data.length == 1)
                    return resolve(takipDosyalar[0]);
                let excelKacinciIcra = app.$uyapHelper.onlyDigit(icraDairesi);
                let excelIcraIl = app.$uyapHelper.turkishToUpper(icraDairesi.split(" ")[0].trim()); //Ankara 1 icra dairesindeki "Ankara"
                for (let i = 0; i < takipDosyalar.length; i++) {
                    let kacinciIcra = app.$uyapHelper.onlyDigit(takipDosyalar[i].birimAdi);
                    let dataIcraIl = app.$uyapHelper.turkishToUpper(takipDosyalar[i].birimAdi.split(" ")[0].trim());
                    if (excelKacinciIcra == kacinciIcra && excelIcraIl == dataIcraIl) {
                        return resolve(takipDosyalar[i]);
                    }
                }
                return reject(this.err)
            } else {
                return reject(this.err)
            }
        })
    }

}

