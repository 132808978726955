import {MDB} from "../MDB";
import {IBorcluTakbisSorgulamaSonuc} from "../uyap/BorcluTakbisSorgulama";
import HacizInjectable from "./HacizInjectable";
import {IIcraDosyasiSonuc} from "../uyap/BorcluIcraDosyasi";
import {HacizEvrakTypeEnum} from "../uyap/enum/HacizEvrakTypeEnum";
import TakipDosya from "@/plugins/uyap-plugin/uyap/classes/TakipDosya";

const mdb = new MDB();

export default class HacizController extends HacizInjectable {
    constructor(borclu: any, dosya: TakipDosya) {
        super(borclu, dosya);
    }

    // TODO: dosya id sıkıntı çıkarabilir tekrardan istek atıp eşitlemek gerekebilir
    alacakliOlduguDosyaEkle(kayitliDosya: IIcraDosyasiSonuc) {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.ALACAKLI_OLDUGU_DOSYA);
        let talepObj = Object.assign({}, obj, {
            "sorguId": "0",
            "dosyaId": kayitliDosya.dosyaId,
            "kisiKurumId": kayitliDosya.guncelKisiKurumId,
            "birimAdi": kayitliDosya.birimAdi,
            "dosyaNo": kayitliDosya.dosyaNo,
            "dosyaTurKod": kayitliDosya.dosyaTurKodu
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    aracHacizEkle(hacizSerhi: number, plaka: string, plakaYildizli: string) {
        let hacizObj = MDB.haciz.aracHacizParams.find((item: any) => item.value == hacizSerhi);
        if (!hacizObj) {
            hacizObj = MDB.haciz.aracHacizParams[0];
        }
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.ARAC_HACZI);
        let talepObj = Object.assign({}, obj, {
            "sorguId": 0,
            "plaka": plaka,
            "plakaYildizli": plakaYildizli,
            "hacizSerhi": hacizObj.value.toString(),
            "hacizSerhiText": hacizObj.name
        });
        this.evrakTutar += obj.talepMasrafi; //0
        console.log("aracHacizEkle", talepObj);
        this.talepBilgileri.push(talepObj);
    }

    sgkMossipHacizEkle(params: any) {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.SGK_MOSSIP_HACZI);

        let kisiTipString = "";
        if (params.kisiTipString && Array.isArray(params.kisiTipString)) {
            kisiTipString = params.kisiTipString.join(", ");
        } else if (params.kisiTipString) {
            kisiTipString = params.kisiTipString;
        }

        let kurumAdi = params.kurumAdi || "";
        if (!kurumAdi.includes("Mossip")) {
            kurumAdi = kurumAdi + " Mossip";
        }
        
        let talepObj = Object.assign({}, obj, {
            fields: [
                {
                    "id": "kurumAdi",
                    "title": "Kurum Adı"
                }
            ],
            kurumAdi: params.kurumAdi,
            kisiTipString: kisiTipString
        });
        this.evrakTutar += obj.talepMasrafi; //0
        console.log("sgkMossipHacizEkle", talepObj);
        this.talepBilgileri.push(talepObj);
    }

    bankaHacizEkle() {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.BANKA_HACZI);
        let talepObj = Object.assign({}, obj, {
            "sorguId": "",
            "tarafList": "",
            "bankaList": this.borcluBloc.haciz.bankaHesapHacziParams.bankaList.join(','),
            "hesapList": this.borcluBloc.haciz.bankaHesapHacziParams.hesapList.join(','),
            "evrakAdi": this.borcluBloc.haciz.bankaHesapHacziParams.evrakAdi,
            "haricAdi": this.borcluBloc.haciz.bankaHesapHacziParams.haricAdi,
            "evrakAdiSTR": this.borcluBloc.haciz.bankaHesapHacziParams.evrakAdiSTR,
            "haricAdiSTR": this.borcluBloc.haciz.bankaHesapHacziParams.haricAdiSTR
        });
        if (this.borcluBloc.haciz.bankaHesapHacziParams.evrakAdi == "IHB_3" || this.borcluBloc.haciz.bankaHesapHacziParams.evrakAdi == "IHB_2") {
            talepObj["tebligTarihi"] = this.borcluBloc.haciz.bankaHesapHacziParams.tebligTarihi;
        }
        this.evrakTutar += obj.talepMasrafi * this.borcluBloc.haciz.bankaHesapHacziParams.bankaList.length; //0
        this.talepBilgileri.push(talepObj);
    }

    postaCekiHacziEkle() {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.POSTA_CEKI_HACZI);
        let talepObj = Object.assign({}, obj, {
            "sorguId": "0",
        });
        this.evrakTutar += obj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    ihbarname89_1Ekle(params: any) {
        let talepObj = {
            "grupKodu": 0,
            "talepKodu": 5,
            "talepAdi": "89-1 İhbarname Talebi",
            "talepKisaAdi": "89-1 İhbarname Talebi",
            "talepMasrafi": 0,
            "className": "AvukatTalep89_1DVO",
            "postaMasrafId": 0,
            "dosyaDurum": "A",
            "sorguId": "",
            "dosyaId": this.dosyaBloc.dosyaId,
            "kisiKurumId": params.kisiKurumId,
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "tarafList": params.tarafList
        }
        this.evrakTutar += talepObj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    ihbarname89_2Ekle(params: any) {
        let talepObj = {
            "grupKodu": 0,
            "talepKodu": 43,
            "talepAdi": "89-2 İhbarname Talebi",
            "talepKisaAdi": "89-2 İhbarname Talebi",
            "talepMasrafi": 0,
            "className": "AvukatTalep89_2DVO",
            "postaMasrafId": 0,
            "dosyaDurum": "A",
            "sorguId": "",
            "tebligTarihi": params.tebligTarihi,
            "dosyaId": this.dosyaBloc.dosyaId,
            "kisiKurumId": params.kisiKurumId,
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "tarafList": params.tarafList
        }
        this.evrakTutar += talepObj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    ihbarname89_3Ekle(params: any) {
        let talepObj = {
            "grupKodu": 0,
            "talepKodu": 44,
            "talepAdi": "89-3 İhbarname Talebi",
            "talepKisaAdi": "89-3 İhbarname Talebi",
            "talepMasrafi": 0,
            "className": "AvukatTalep89_3DVO",
            "postaMasrafId": 0,
            "dosyaDurum": "A",
            "sorguId": "",
            "tebligTarihi": params.tebligTarihi,
            "dosyaId": this.dosyaBloc.dosyaId,
            "kisiKurumId": params.kisiKurumId,
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "tarafList": params.tarafList
        }
        this.evrakTutar += talepObj.talepMasrafi;
        this.talepBilgileri.push(talepObj);
    }

    tasinmazHacizEkle(tasinmaz: IBorcluTakbisSorgulamaSonuc) {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == "AvukatTalepGayrimenkulHacziDVO");
        let talepObj = Object.assign({}, obj, {
            "tasinmazId": tasinmaz.tasinmazDVO.tasinmazId,
            "sorguId": 0,
            "il": tasinmaz.tasinmazDVO.il,
            "ilce": tasinmaz.tasinmazDVO.ilce,
            "mahalle": tasinmaz.tasinmazDVO.mahalle,
            "ada": tasinmaz.tasinmazDVO.ada,
            "parsel": tasinmaz.tasinmazDVO.parsel,
        });
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    tasinirHacizTalimatiEkle(params: any) {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.TASINIR_HACIZ_TALIMATI);
        let talepObj = Object.assign({}, obj, {
            "sorguId": "",
            "birimId": params.birimId,
            "birimAdi": params.birimAdi,
            "aciklama": params.aciklama,
            "tarafList": ""
        });
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    tasinirHacizEkle(aciklama: string) {
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.TASINIR_HACZI);
        let talepObj = Object.assign({}, obj, {
            "sorguId": "",
            "aciklama": aciklama
        });
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    maasHacizEkle(params: any) {
        let calismaDurumuObj = MDB.haciz.maasHacizParams.find((i: any) => i.value == params.calismaDurumu);
        if (!calismaDurumuObj) {
            calismaDurumuObj = MDB.haciz.maasHacizParams[0];
        }
        let obj = mdb.hacizEvrakTipleri.find((i: any) => i.className == HacizEvrakTypeEnum.MAAS_HACZI);
        params.nafakaTutari = String(params.nafakaTutari);
        params.nafakaTutari = params.nafakaTutari.replace('.', ',');
        let talepObj = Object.assign({}, obj, {
            "sorguId": "",
            "kurumAdi": params.kurumAdi,
            "calismaDurumu": calismaDurumuObj.value.toString(),
            "calismaDurumuText": calismaDurumuObj.name,
            "maasininBirBoluDordu": !!params.maasininBirBoluDordu,
            "alacaklarininTamami": !!params.alacaklarininTamami,
            "emekliIkramiyeHaczi": !!params.emekliIkramiyeHaczi,
            "nafaka": !!params.nafaka,
            "nafakaTutari": (!params.nafaka ? '' : params.nafakaTutari),
            "aciklama": params.aciklama
        });
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

}
