import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import {ProgramPaths} from "@/enum/ProgramPaths";
import {Helper} from "@/plugins/helper";
import {NavigationGuardNext} from "vue-router/types/router";
import {store} from "@/store";
import app from "@/main";
import {PathToProduct} from "@/enum/Product";

Vue.use(VueRouter)

const clearCache = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    localStorage.removeItem('accessToken');
    sessionStorage.clear();
    next();
}

const kullaniciIzinKontrol = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    try {
        if (store.getters.accessToken) {
            let user = store.getters.userData;
            if (!user.id) {
                let tenantList = await store.dispatch("getTenantList");
                store.commit('setTenantId', tenantList[0].tenant_id)
                user = await app.$http.get("/api/v1/ben");
                store.commit('setUser', user);
            }
            let urunPath = to.path.split('/')[1];
            // @ts-ignore
            if (helper.licenceIsValid(PathToProduct[urunPath])) {
                // @ts-ignore
                let meta: { isAuthenticationRequired: boolean, izinKey: string } = to.meta;
                if (!meta.isAuthenticationRequired || (meta.isAuthenticationRequired && user.roles[0].permissions.find((permission: any) => permission.name === meta.izinKey)))
                    next();
                else
                    next(ProgramPaths.erisimYok);
            } else
                next(ProgramPaths.erisimYok);
            //TODO: özel satınal ekranı olabilir
        } else {
            console.error('Token not found.');
            if (to.path != ProgramPaths.giris)
                next(ProgramPaths.giris);
        }
    } catch (e) {
        localStorage.removeItem('accessToken');
        sessionStorage.clear();
        console.error(e);
        Vue.prototype.$toast.error('Hata: ' + e.message);
        if (to.path != ProgramPaths.giris)
            next(ProgramPaths.giris);
    }
};

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: ProgramPaths.giris,
        meta: {
            isAuthenticationRequired: false,
        },
    },
    {
        path: '/giris',
        component: () => import('@/views/common/HomeView.vue'),
        beforeEnter: clearCache,
        meta: {
            isAuthenticationRequired: false,
        },
    },
    {
        path: '/giris-kontrol',
        component: () => import('@/views/common/LoginCallback.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro,
        component: () => import('@/views/common/YapimAsamasinda.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro + '/buro-bilgileri',
        component: () => import('@/views/ofispro/BuroListeView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'buro-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/buro/:id',
        component: () => import('@/views/ofispro/BuroDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'buro-detay'
        }
    },
    {
        path: ProgramPaths.ofispro + '/personel/:id',
        component: () => import('@/views/ofispro/PersonelDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'buro-calisan-bilgisi-detay'
        }
    },
    {
        path: ProgramPaths.ofispro + '/vekaletler/',
        component: () => import('@/views/ofispro/VekaletListeView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'vekalet-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/vekalet/:id',
        component: () => import('@/views/ofispro/VekaletDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'vekalet-detay'
        }
    },
    {
        path: ProgramPaths.ofispro + '/raporlar',
        component: () => import('@/views/ofispro/RaporListeView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'rapor-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/rapor/:id',
        component: () => import('@/views/ofispro/RaporDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'rapor-detay'
        }
    },
    {
        path: ProgramPaths.ofispro + '/dilekceler',
        component: () => import('@/views/ofispro/DilekceView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'dilekce-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/kasa',
        component: () => import('@/views/ofispro/KasaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'kasa-modulu'
        }
    },
    //TODO:ÇALIŞMADIĞI İÇİN KAPATILDI 1@sebo
    /*
    {
        path: ProgramPaths.ofispro + '/klasorler',
        component: () => import('@/views/ofispro/KlasorlerView.vue'),
        meta: {
            isAuthenticationRequired: true,
        }
    },*/
    {
        path: ProgramPaths.ofispro + '/ajanda',
        component: () => import('@/views/ofispro/AjandaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'ajanda-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/icw-aktar',
        component: () => import('@/views/ofispro/AktarView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'aktar-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/kullanici-izinleri',
        component: () => import('@/views/ofispro/OfisTercihlerView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'tercih-modulu'
        }
    },
    {
        path: ProgramPaths.ofispro + '/hesap-bilgileri',
        component: () => import('@/views/ofispro/HesapBilgileriView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro + '/muvekkil/:id',
        component: () => import('@/views/ofispro/MuvekkilDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'muvekkil-detay'
        }
    },
    {
        path: ProgramPaths.ofispro + '/islem-kaydi',
        component: () => import('@/views/ofispro/LogView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'islem-kaydi'
        }
    },
    {
        path: ProgramPaths.ofispro + '/dosya-raporlama-tipleri',
        component: () => import('@/views/ofispro/raporlama/DosyaRaporlamaTipleriView.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro + '/genel-istatistikler',
        component: () => import('@/views/ofispro/raporlama/GenelRaporlamaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
            izinKey: 'genel-istatistikler'
        }
    },{
        path: ProgramPaths.ofispro + '/tahsilatlar',
        component: () => import('@/views/ofispro/raporlama/TahsilatlarView.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },{
        path: ProgramPaths.ofispro + '/karsilastirmali-tahsilatlar',
        component: () => import('@/views/ofispro/raporlama/KarsilastirmaliRaporlamaView.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },{
        path: ProgramPaths.ofispro + '/takip-ve-tahsilat-raporu',
        component: () => import('@/views/ofispro/raporlama/TakipVeTahsilatRaporlamaView.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro + '/aktar-detay/:id',
        component: () => import('@/views/ofispro/AktarDetay.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro + '/aktarilan-detay/:id',
        component: () => import('@/views/ofispro/AktarilanDosyaDetay.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.ofispro + '/aktarilmayan-detay/:id',
        component: () => import('@/views/ofispro/AktarilmayanDosyaDetay.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.icrapro,
        component: () => import('@/views/common/YapimAsamasinda.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.icrapro + '/borclu/:id',
        component: () => import('@/views/icrapro/BorcluDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'borclu-detay'
        }
    },
    {
        path: ProgramPaths.icrapro + '/takip-alt-tip/:id',
        component: () => import('@/views/icrapro/TakipAltTipView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'takip-alt-tip-detay'
        }
    },
    {
        path: ProgramPaths.icrapro + '/takipler/',
        component: () => import('@/views/icrapro/TakipListView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'takip-listesi'
        }
    },
    {
        path: ProgramPaths.icrapro + '/takip/:id',
        component: () => import('@/views/icrapro/TakipDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'takip-detay'
        }
    },
    {
        path: ProgramPaths.icrapro + '/takip-ekle/',
        component: () => import('@/views/icrapro/TakipEkleView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'takip-ac'
        }
    },
    {
        path: ProgramPaths.icrapro + '/takip-ekle/:id',
        component: () => import('@/views/icrapro/TakipEkleView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'takip-detay'
        }
    },
    {
        path: ProgramPaths.icrapro + '/tercihler',
        component: () => import('@/views/icrapro/TercihlerView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'tercihler-modulu'
        }
    },
    {
        path: ProgramPaths.icrapro + '/toplu-isler',
        component: () => import('@/views/icrapro/TopluIslerView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'toplu-xml-indir'
        }
    },
    {
        path: ProgramPaths.icrapro + '/toplu-excel',
        component: () => import('@/views/icrapro/TopluExcelTakip.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
            izinKey: 'toplu-excel-takip'
        }
    },
    {
        path: ProgramPaths.icrapro + '/hesaplayici',
        component: () => import('@/views/icrapro/HesaplayiciView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: true,
            izinKey: 'faiz-hesaplama-modulu'
        }
    },
    {
        path: ProgramPaths.eicrapro,
        component: () => import('@/views/eicrapro/IslemlerView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/deneme-tahtasi',
        component: () => import('@/views/eicrapro/Servisler.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/evrak-gonder',
        component: () => import('@/views/eicrapro/EvrakGonderView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/harc-masraf-odeme',
        component: () => import('@/views/eicrapro/HarcMasrafOdeView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/kisi-kurum-sorgula',
        component: () => import('@/views/eicrapro/KisiKurumSorgulaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/islemler',
        component: () => import('@/views/eicrapro/IslemlerView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-odeme',
        component: () => import('@/views/eicrapro/MtsOdemeView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-hazirla',
        component: () => import('@/views/eicrapro/TakipHazirlaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-ac',
        component: () => import('@/views/eicrapro/TakipAcView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-takip-hazirla',
        component: () => import('@/views/eicrapro/MtsTakipHazirlaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-takip-ac',
        component: () => import('@/views/eicrapro/MtsTakipAcView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-hash-list',
        component: () => import('@/views/eicrapro/HashListView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-tebligat-gonder',
        component: () => import('@/views/eicrapro/MtsTebligatView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-hazirla',
        component: () => import('@/views/eicrapro/TakipHazirlaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-sorgula',
        component: () => import('@/views/eicrapro/TakipSorgulaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/vekil-sorgula',
        component: () => import('@/views/eicrapro/VekilSorgulaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/toplu-haciz-talebi-gonder',
        component: () => import('@/views/eicrapro/TopluHacizHazirlaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/toplu-tebligat-talebi-gonder',
        component: () => import('@/views/eicrapro/TopluTebligatHazirlaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/toplu-genel-talep-gonder',
        component: () => import('@/views/eicrapro/TopluGenelTalepHazirlaView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/hazir-evrak-gonder',
        component: () => import('@/views/eicrapro/HazirEvrakGonderView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/evrak-indir',
        component: () => import('@/views/eicrapro/EvrakIndirView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/makbuz-indir',
        component: () => import('@/views/eicrapro/MakbuzIndirView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-hazirla-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/TakipHazirlaIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-ac-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/TakipAcIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-takip-hazirla-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/MtsTakipHazirlaIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-takip-ac-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/MtsTakipAcIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/takip-sorgula-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/TakipSorgulaIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/evrak-gonder-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/EvrakGonderIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/hazir-evrak-gonder-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/HazirEvrakGonderIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/makbuz-indir-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/MakbuzIndirIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/genel-talep-gonder-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/TopluGenelTalepGonderIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/tebligat-gonder-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/TopluTebligatGonderIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/haciz-gonder-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/TopluHacizGonderIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/harc-masraf-ode-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/HarcMasrafOdeIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-ode-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/MtsOdemeIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/mts-tebligat-gonder-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/MtsTebligatGonderIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.eicrapro + '/evrak-indir-detay/:id',
        component: () => import('@/views/eicrapro/islem-detay/EvrakIndirIslemDetayView.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.davapro,
        component: () => import('@/views/common/YapimAsamasinda.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: ProgramPaths.arabulucupro,
        component: () => import('@/views/common/YapimAsamasinda.vue'),
        beforeEnter: kullaniciIzinKontrol,
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: '/erisim-yok',
        component: () => import('@/views/common/YasakView.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/common/NotFound.vue'),
        meta: {
            isAuthenticationRequired: false,
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const helper = new Helper();
router.beforeEach(async (to, from, next) => {
    if (store.getters.accessToken && to.path == ProgramPaths.giris)
        next(ProgramPaths.ofispro);
    next();
});

export default router
