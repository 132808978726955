import app from "@/main";
import {IDosyaEvrak, TumEvraklar} from "@/plugins/uyap-plugin/uyap/DosyaEvraklar";


export default class EvrakIslemleri {
    items: IDosyaEvrak[] = [];
    dosyaId: number = 0;
    takipId: any = 0;

    async evraklar(dosyaId: number) {
        if(this.items.length > 0 && dosyaId == this.dosyaId)
            return;
        let evraklarRes = await app.$uyap.DosyaEvraklar().run({
            dosyaId: dosyaId
        })
        console.log("evraklarRes.tumEvraklar",evraklarRes.tumEvraklar);
        for (let tumEvraklarKey in evraklarRes.tumEvraklar) {
            this.items = this.items.concat(evraklarRes.tumEvraklar[tumEvraklarKey]);
        }
        this.dosyaId = dosyaId;
    }
    async dosyaKapaliTebligatlar(dosyaId: number): Promise<IDosyaEvrak[]>{
        await this.evraklar(dosyaId);
        return  this.items.filter((item: IDosyaEvrak)=>{
            return item.tur == "Kapalı Tebligat"
        });
    }

    async dosyaBarkodNoBul(dosyaId: number){
        let kapaliTebligatlar: IDosyaEvrak[] = await  this.dosyaKapaliTebligatlar(dosyaId);
        for (let item of kapaliTebligatlar){
            let barkod = await this.evrakBarkodOku(dosyaId,item.evrakId);
        }
    }

    async evrakBarkodOku(dosyaId: number,evrakId: string){
        //let response = await app.$http.get("http://localhost:8080/evrak.pdf",{responseType:"blob"});
        let evrakBlob = await app.$uyap.EvrakIndir().run({
            dosyaId : dosyaId,
            evrakId : evrakId
        });
        //TODO LARAVEL APIYE GIDECEK TALEP BURADA URL DEGISECEK
        let fdata = new FormData();
        fdata.append("file",evrakBlob);
        let readedPdfResponse = await app.$http.post("http://localhost:8085/",fdata);
        let barkodResponse = await app.$uyap.PttBarkodSorgula().run({
            q:readedPdfResponse.data.barkodNo
        })
        //BURADADA API A BILGILERI YAZACAZ TAKIBE ILISKIN
        let response = await app.$http.post("tebligat/" + this.takipId , {
            barkodResponse,
            readedPdfResponse
        });
        console.log("response",response);
        return response;
    }

}