import {UyapRequest} from "./UyapRequest";

export interface AvukatIcraTalepEvrakiGonderTalep {
    formData: boolean,
    data: FormData
}

export interface AvukatIcraTalepEvrakiGonderCevap {
    message: string,
    result: string
}

export class AvukatIcraTalepEvrakiGonder extends UyapRequest<AvukatIcraTalepEvrakiGonderTalep, AvukatIcraTalepEvrakiGonderCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {"type":"success","message":"İnternet yoluyla gönderdiğiniz evrakları icra dairesindeki dosyada görebilirsiniz.","log":""};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'avukatIcraTalepEvrakiGonder.ajx';
    }

    async parse(data: any): Promise<AvukatIcraTalepEvrakiGonderCevap> {
        // Veri doğrulama ekle
        if (!data) {
            console.error("AvukatIcraTalepEvrakiGonder: Cevap verisi boş");
            return {
                message: "Veri gelmedi, işlem başarısız.",
                result: "error"
            };
        }
        
        try {
            // Hata durumu kontrolü
            if (data.type === "error" || data.status === "error") {
                console.error("AvukatIcraTalepEvrakiGonder: Hata:", data);
                return {
                    message: data.message || "Evrak gönderimi başarısız.",
                    result: "error"
                };
            }
            
            // Başarılı durum
            return {
                message: data.message || "Evrak gönderimi başarılı.",
                result: data.type || "success"
            };
        } catch (error) {
            console.error("AvukatIcraTalepEvrakiGonder parse hatası:", error);
            return {
                message: error instanceof Error ? error.message : "Bilinmeyen bir hata oluştu.",
                result: "error"
            };
        }
    }
}

export default AvukatIcraTalepEvrakiGonder;