import {UyapRequest} from "./UyapRequest";
import {ISorguSonuc} from "./CommonInterfaces";
import app from "@/main";


export interface BorcluBesTalep {
    dosyaId: string|number,
    kisiKurumId: number,
    sorguBakiye: number | string,
}

export interface BorcluBesCevap extends ISorguSonuc<IBesSonuc> {

}

export interface IBesSonuc{
    data: {hacizDosyaNo:number,isNew:boolean,tcknList:ITcknList[]},
}

export interface ITcknList{
    isNew: boolean,
    sirketList: ISirketList[],
    tckn: string,
    selected: boolean
}

export interface ISirketList{
    isNew: boolean,
    sirketAck: string
}

export default class BorcluBes extends UyapRequest<BorcluBesTalep, BorcluBesCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluBes_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isGecerlilikAktifAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","yabanci":false,"mavikart":false,"sorguTuru":0,"tcKimlikNo":"1********84","adi":"AD**","soyadi":"KÖ****","babaAdi":"M***** A**","anaAdi":"E****","dogumTarihiStr":"1965","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":43047696,"turu":0},"sorgulayanBilgileri":"Av.HALİT SALDAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Eskişehir 1. İcra Dairesi","birimId":"1003953","orgKodu":"1.04.055.000.6001","duzey1":"1","duzey2":"04","duzey3":"055","duzey4":"000","duzey5":"6001","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2014/13385","dosyaNoYil":2014,"dosyaSira":13385,"dosyaId":"AixR7y6H1BoLWj@alwwd5ogEAzA+s5T1QnkCpM2glU@xKJz0xZ892Yqm5qHaeZWN","dosyaAcilisTarihi":"09/12/2014 11:09","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"SENNUR KARPUZ, TUBA SEDİR, TUBA SEDİR, MEHMET ALİ KÖKSAL, HAYRİYE KÖKSAL, HAYRİYE KÖKSAL, TÜRKİYE ŞEKER FABRİKALARI ANONİM ŞİRKETİ GENEL MÜDÜRLÜĞÜ ANKARA ŞEKER FABRİKASI MÜDÜRLÜĞÜ, AHMET KÖKSAL, AHMET KÖKSAL, ADEM KÖKSAL, ŞÜKRÜ URAZ ÖZCAN, EMİNE KÖKSAL, SENNUR KARPUZ, SENNUR KARPUZ","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"17/03/2025 11:37","sorguSonucDVO":{"data":{"tcknList":[{"tckn":"12055254284","sirketList":[{"sirketAck":"Anadolu Hayat Emeklilik A.Ş.","isNew":true}],"isNew":true}],"hacizDosyaNo":0,"isNew":true},"isNew":true},"sorgulamaTuru":"BES","sorguSonucu":false,"hasError":false,"hasData":false,"isMock":false}
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borcluBilgileriGoruntuleBES.ajx';
    }

    async parse(data: any): Promise<BorcluBesCevap> {
        if (data.sorguSonucDVO)
            data.sorguSonucu=true;
        if (data.sorguSonucu) {
            // @ts-ignore
            data.sorguSonucDVO.data.tcknList.forEach((tc) => {
                tc.selected = true;
            })
            data.message = "Kişiye ait bes kaydı var";
        } else if (typeof data.sorguSonucDVO == "string")
            data.message = data.sorguSonucDVO;
        else
            data.message = "Kişiye ait bes kaydı yok";
        return data;
    }
}

