// @ts-nocheck

import app from "@/main";
import {DosyaEvraklarCevapRow, IDosyaEvrak} from "../uyap/DosyaEvraklar";
import * as xml2js from "xml2js";
import JSZip from "jszip";
import UyapTakipTalebiContent from "./UyapTakipTalebiContent";
import {AvukatIcraDosyalariTalep} from "../uyap/AvukatIcraDosyalari";
import EvrakIslemleri from "@/plugins/uyap-plugin/uyap/classes/EvrakIslemleri";

export default class TakipSenkronize {
    queueCount: number = 0;
    workingNumber: number = 0;

    async runQueryForSelectedItems(items: any[]){
        console.log("items",items);
        for(let item of items){
            console.log("item",item);
            try{
                let takip = await app.$uyap.DosyaBul().run(item);
                let evrakIslemleri = new EvrakIslemleri();
                let kapaliTebligatlar: IDosyaEvrak[] = await  evrakIslemleri.dosyaKapaliTebligatlar(takip.dosyaId);
                let borclular = await app.$uyap.DosyaBorclulari().run({
                    dosyaId : takip.dosyaId
                });
                console.log("kapaliTebligatlar",kapaliTebligatlar);
                console.log("borclular",borclular);
                for (let evrak of kapaliTebligatlar){
                    console.log("evrak",evrak);
                    try {

                        ///api/v1/tebligat-pdf-tara
                        let evrakBlob = await app.$uyap.EvrakIndir().run({
                            dosyaId : takip.dosyaId,
                            evrakId : evrak.evrakId
                        });
                        let fdata = new FormData();
                        fdata.append("pdf",evrakBlob);
                        let readedPdfResponse = await app.$http.post("/api/v1/tebligat-pdf-tara",fdata);
                        console.log("readedPdfResponse",readedPdfResponse)
                        let barkodResponse = await app.$uyap.PttBarkodSorgula().run({
                            q:readedPdfResponse.data.barkodNo
                        });
                        console.log("barkodResponse",barkodResponse);
                        let borclu = {};
                        if(readedPdfResponse.data.isKurum == true)
                             borclu = borclular.find(x=>x.borcluBilgileri.kurumAdi == readedPdfResponse.data.kurumAd.trim());
                        else
                            borclu = borclular.find(x=>(x.borcluBilgileri.adi + " " + x.borcluBilgileri.soyadi) == readedPdfResponse.data.adSoyad.trim())
                        console.log("selectedborclu",borclu)
                        let tebligatOlustur = await app.$http.post("/api/v1/tebligat-olustur",{
                           id : item.id,
                           borclu : borclu,
                           barkodResponse : barkodResponse,
                           readedPdfResponse : readedPdfResponse.data
                        });
                        console.log("tebligatOlustur",tebligatOlustur);
                    }catch (er) {
                        console.log("er",er);
                    }
                }

            }catch (e) {
                console.log("error TRY",e);
            }

        }
    }
    borcluBul(borclular: any[] , borclu: any) : {

    }

    async runQueryLastOneWeek(): Promise<void> {
        try {
            let requestData = new AvukatIcraDosyalariTalep();
            requestData.dosyaAcilisTarihiStart = app.$uyapHelper.getDateNow(".", "day -7");
            requestData.dosyaAcilisTarihiEnd = app.$uyapHelper.getDateNow(".", "");
            let acikDosyalar = await app.$uyap.AvukatIcraDosyalari().run(requestData);
            this.queueCount = acikDosyalar.items.length;
            let key = 0;
            for (const dosya of acikDosyalar.items) {
                this.workingNumber = key + 1;
                let evraklar = await app.$uyap.DosyaEvraklar().run({
                    dosyaId: dosya.dosyaId
                });
                let takipTalebi = evraklar.items.find((x: DosyaEvraklarCevapRow) => x.turu == "Takip Talebi");
                if (takipTalebi) {
                    //Takip Talebi ve Ekleri (1)
                    let takipTalebiBlob = await app.$uyap.EvrakIndir().run({
                        dosyaId: dosya.dosyaId,
                        evrakId: takipTalebi.evrakId,
                        yargiTuru: 1
                    })
                    let parsedContent: any = await TakipSenkronize.parseContentXml(takipTalebiBlob);
                    let uyapTakipTalebiContent = UyapTakipTalebiContent.parse(parsedContent);
                    await app.$http.post('/api/v1/uyap-takip/', {
                        hash: uyapTakipTalebiContent.hash,
                        type: "uyap",
                        dosya: uyapTakipTalebiContent,
                        uyap_dosya_id: dosya.dosyaId,
                        status: 5,
                    });
                    //let response = await app.$http
                    console.log("created uyapTakipTalebiContent", uyapTakipTalebiContent);
                    key++;
                }
            }
        } catch (e) {
            throw new Error(e.message);
        }
    }

    async run(dosyaId: number): Promise<any> {
        let evraklar = await app.$uyap.DosyaEvraklar().run({
            dosyaId: dosyaId.toString()
        });
        console.log("evraklar", evraklar);
        let takipTalebi = evraklar.items.find((x: DosyaEvraklarCevapRow) => x.turu == "Takip Talebi");
        // @ts-ignore
        let odemeIcraEmri = evraklar.items.findLast((x: DosyaEvraklarCevapRow) => x.turu == "Ödeme İcra Emri");
        console.log("takipTalebi", takipTalebi);
        console.log("odemeIcraEmri", odemeIcraEmri);
        if (takipTalebi) {
            console.log("takiptalebi bulundu", takipTalebi);
            //Takip Talebi ve Ekleri (1)
            let takipTalebiBlob = await app.$uyap.EvrakIndir().run({
                dosyaId: dosyaId,
                evrakId: takipTalebi.evrakId,
                yargiTuru: 1
            })
            let content = await TakipSenkronize.parseContentXml(takipTalebiBlob);
            console.log("CONTENT", content);
            console.log("CONTENT", JSON.stringify(content));
        }
        /*else if(odemeIcraEmri){
            console.log("odemeIcraEmri bulundu",odemeIcraEmri);
            let blob = await app.$uyap.EvrakIndir().run({
                dosyaId:dosyaId,
                evrakId:odemeIcraEmri.evrakId,
                yargiTuru:1
            })
            let content = await TakipSenkronize.parseContentXml(blob);
            console.log("CONTENT",content);
            console.log("CONTENT",JSON.stringify(content));
        }

         */
    }

    static async parseContentXml(blob: Blob): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let zip = await JSZip.loadAsync(blob);
                // @ts-ignore
                zip.file("content.xml").async("string").then(function (xml) {
                    let startIndex = xml.indexOf("<data>") + 6;
                    let endIndex = xml.indexOf("</data>");
                    let xmlContentData = xml.substr(startIndex, endIndex - startIndex);
                    xmlContentData = "<data>" + xmlContentData + "</data>";
                    console.log(xmlContentData);
                    let parseString = xml2js.parseString;
                    parseString(xmlContentData, {explicitArray: false}, (error: Error | null, result: any) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve(result.data);
                    });
                });
            } catch (e) {
                return reject(e);
            }
        })
    }

}