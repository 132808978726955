export class Ihbarname89Params {
    kisiKurumId: number = 0;
    tebligatTuru: number = 0;
    tebligatTuruAciklama: string = "";
    adresTuru: number = 0;
    adresTuruAciklama: string = "";
    adres: string = "";
    tarafList: string = "" // TODO  boş string gidiyor belki bazı durumlarda dolu gidiyor olabilir detaylı incelemedim ..
}

export class MaasHacziParams {
    kurumAdi: string = "";
    calismaDurumu: string = "";
    aciklama: string = "";
    maasininBirBoluDordu: boolean = true;
    alacaklarininTamami: boolean = false;
    emekliIkramiyeHaczi: boolean = false;
    nafaka: boolean = false;
    nafakaTutari: string = ""
}

export class BankaHesapHacziParams {
    evrakAdi: string = "MZK";
    evrakAdiSTR: string = "Haciz Müzekkeresi";
    haricAdi: string = "MAAS";
    haricAdiSTR: string = "Maaş Hesabı Hariç";
    hesapList: string[] = [];
    bankaList: number[] = [];
    tebligTarihi: string = "";// 89/2 ve 89/3 de isteniyor. Format "22.02.2022"
}

export class SGKMossipHacziParams {
    dosyaId: string = "";
    fileName: string = "";
    kisiKurumId: number = 0;
    talepBilgileri: string = "";
}

export class DosyaBorcluHacizInterface {
    tasinirHacziParams: {
        aciklama: string
    } = {
        aciklama: ""
    }
    tasinirHacizTalimatiParams: {
        birimAdi: string,
        birimId: string,
        aciklama: string
    } = {
        birimId: "",
        birimAdi: "",
        aciklama: ""
    }
    bankaHesapHacziParams: BankaHesapHacziParams = new BankaHesapHacziParams();
    maasHacziParams: MaasHacziParams = new MaasHacziParams();
    ihbarname89_1Params: Ihbarname89Params = new Ihbarname89Params();
    ihbarname89_2Params: Ihbarname89Params = new Ihbarname89Params();
    ihbarname89_3Params: Ihbarname89Params = new Ihbarname89Params();
    sgkMossipHacziParams: SGKMossipHacziParams = new SGKMossipHacziParams();
}