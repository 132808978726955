import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";
import JSZip from "jszip";


export interface IIcraTalepEvrakHazirlaParamsData {
    dosyaId: string | number,
    filename: string,
    dosyaEsasNo: string,
    kisiKurumId: number,
    talepBilgileri: string
}

export default class IcraTalepEvrakHazirla extends UyapRequest<IIcraTalepEvrakHazirlaParamsData, Blob> {

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    getResponseType(): ResponseType {
        return 'blob'
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {}
    }

    getUrl(): string {
        return this.ImzaClientAddr + "getIcraTalepEvrakHazirla.uyap";
    }

    async parse(data: Blob): Promise<Blob> {
        return new Promise((resolve, reject) => {
            if (!data || data.size === 0) {
                return reject(new Error("Boş veya geçersiz UDF dosyası alındı"));
            }
            
            try {
                let blobData = data;
                let reader = new FileReader();
                reader.readAsText(data); // udf base64 ceviriliyor
                
                reader.onloadend = function () {
                    try {
                        let textContent: any = reader.result;
                        if (!textContent) {
                            return reject(new Error("UDF içeriği okunamadı"));
                        }
                        
                        if (textContent.substr(0, 2) == "PK") {
                            console.log("UDF zaten ZIP formatında");
                            return resolve(blobData);
                        } else {
                            /**
                             * 08/04/2019
                             * uyap güncellemesi ile
                             * GELEN UDF zip olarak gelmiyor artık gelen xml datası mı diye text e çevirilip kontrol ediliyor.
                             * eğer zip olarak gelirse X tarihte ona göre de kontrol koyuldu.
                             * PK gelen data zip mi değilmi kontrolüdür.
                             * Emre
                             * @return zip blob data;
                             */
                            let zip = new JSZip();
                            zip.file("content.xml", blobData);
                            
                            zip.generateAsync({
                                type: "blob",
                                compression: "DEFLATE",
                                compressionOptions: {
                                    level: 6
                                }
                            }).then(function (content) {
                                if (!content || content.size === 0) {
                                    return reject(new Error("ZIP dönüşümü başarısız oldu"));
                                }
                                
                                let blob = new Blob([content], {type: "application/octet-stream"}); // udf type ına çeviriliyor.
                                console.log("UDF CONVERTING BLOB", blob);
                                return resolve(blob);
                            }).catch(function (err) {
                                console.error("ZIP oluşturma hatası:", err);
                                return reject(err);
                            });
                        }
                    } catch (error) {
                        console.error("UDF işleme hatası:", error);
                        return reject(error);
                    }
                };
                
                reader.onerror = function(error) {
                    console.error("FileReader hatası:", error);
                    return reject(error);
                };
            } catch (error) {
                console.error("Genel UDF işleme hatası:", error);
                return reject(error);
            }
        });
    }
}